export default {
  "dropdown-icon": "ZSN",
  "dropdown": "ZSv",
  "download-button": "ZSo",
  "connections-banner": "ZSn",
  "recommendation-cards": "ZSi",
  "wrapper-with-cards": "ZSA",
  "transactions-wrapper": "ZSY",
  "separator": "ZSh",
  "with-transaction": "ZSf",
  "annual-billing-banner": "ZSK",
  "mt-132": "ZSG",
  "transactions-sidebar": "ZSr",
  "empty-state-wrapper": "ZSb"
};

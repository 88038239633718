export default {
  "header": "qxD",
  "btn-preset": "qxV btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qxL",
  "statements-table": "qxT",
  "no-result": "qxz",
  "lottie-illustration": "qxa",
  "wrapper": "qxH",
  "access-denied": "qxO",
  "access-denied-illustration": "qxm",
  "application-card": "qxp",
  "presets-wrapper": "qxx",
  "error-wrapper": "qxw",
  "error-container": "qxN",
  "error-illustration": "qxv mb-32"
};

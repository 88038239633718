export default {
  "container": "qnG",
  "page-wrapper": "qnr",
  "header": "qnb",
  "back-button-wrapper": "qny",
  "header-inner": "qnU",
  "header-main-wrapper": "qnj",
  "header-main": "qnW",
  "header-right": "qnu",
  "content-wrapper": "qiS",
  "content": "qic",
  "content-body": "qiq",
  "sidebar": "qiZ"
};

export default {
  "header": "qyo",
  "content": "qyn",
  "read-only": "qyi",
  "document-collection-banner": "qyA",
  "submit-banner": "qyY",
  "review-banner": "qyh",
  "disclaimer": "qyf",
  "title": "qyK",
  "card-container": "qyG",
  "representatives-section": "qyr",
  "disabled-href": "qyb"
};

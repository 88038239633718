export default {
  "container": "qYS",
  "compact-header": "qYc",
  "visible": "qYq",
  "page-container": "qYZ",
  "main": "qYR",
  "tabs": "qYQ",
  "tabs-nav": "qYe",
  "tabs-panels": "qYB",
  "tabs-panel": "qYE body-2",
  "sidebar": "qYd",
  "prismic-content": "qYI"
};

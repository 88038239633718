export default {
  "page-wrapper": "qrr",
  "content-wrapper": "qrb",
  "content": "qry",
  "period-field": "qrU",
  "format-options": "qrj",
  "format-option": "qrW",
  "format-radio": "qru",
  "format-description": "qbS body-2"
};

export default {
  "container": "qUN",
  "wrapper": "qUv",
  "grey-header-block": "qUo",
  "connect-app-header": "qUn",
  "connect-app-header-link": "qUi",
  "connect-app-header-content": "qUA",
  "connect-app-header-content-left": "qUY",
  "application-avatar": "qUh",
  "connect-app-header-cta": "qUf",
  "connect-app-header-meta": "qUK",
  "connect-app-content": "qUG",
  "connect-app-description": "qUr",
  "title-wrapper": "qUb",
  "title": "qUy title-1",
  "tag-line": "qUU body-2",
  "connect-app-body": "qUj"
};

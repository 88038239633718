export default {
  "mt-16": "qyV",
  "title": "qyL",
  "avatar": "qyT",
  "edit-organization-form": "qyz",
  "dropdown-content": "qya",
  "city-block": "qyH",
  "zip-code-input": "qyO",
  "cancel-button": "qym",
  "error-message": "qyp",
  "nature-of-operations": "qyx",
  "error": "qyw",
  "legal-code": "qyN",
  "activity-type": "qyv"
};

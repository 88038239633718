export default {
  "container": "qbC",
  "form-container": "qbk",
  "title": "qbJ",
  "title-border": "qbs",
  "tabs-container": "qbF",
  "preview-container": "qbg",
  "email-preview-container": "qbD",
  "close-button": "qbV btn btn--icon-only btn--tertiary btn--large"
};

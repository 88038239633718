export default {
  "splash-landing": "qNA",
  "container": "qNY",
  "options": "qNh",
  "physical": "qNf",
  "card-label": "qNK",
  "physical-cta-container": "qNG",
  "physical-cta": "qNr",
  "coming-soon": "qNb"
};

export default {
  "page-container": "qYY",
  "form": "qYh",
  "close-button": "qYf btn btn--icon-only btn--tertiary btn--large",
  "preview": "qYK",
  "preview-padding": "qYG",
  "title": "qYr",
  "header": "qYb",
  "without-tabs": "qYy",
  "form-section": "qYU",
  "form-footer": "qYj",
  "disclaimer-text": "qYW"
};

export default {
  "wrapper": "qhX",
  "header": "qhC",
  "main": "qhk",
  "frequency": "qhJ body-1",
  "radiogroup": "qhs",
  "divider": "qhF",
  "option-wrapper": "qhg",
  "option-details-wrapper": "qhD",
  "option-label": "qhV",
  "option-name": "qhL",
  "option-details-text": "qhT",
  "spinner-wrapper": "qhz",
  "spinner": "qha"
};

export default {
  "team": "qfY",
  "no-members": "qfh",
  "teams-header": "qff",
  "header-search": "qfK",
  "search-bar": "qfG",
  "invite": "qfr body-2",
  "invite-text": "qfb",
  "empty-illustration": "qfy",
  "empty-title": "qfU title-3",
  "empty-description": "qfj body-2",
  "pagination-footer": "qfW",
  "filter-search": "qfu",
  "fullsize": "qKS",
  "tabs-container": "qKc",
  "tabs-separator": "qKq"
};

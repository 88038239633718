export default {
  "container": "qWJ",
  "security-method": "qWs",
  "icon": "qWF",
  "body": "qWg",
  "status": "qWD body-2",
  "device": "qWV",
  "device-icon": "qWL",
  "subtitle": "qWT body-2",
  "current": "qWz"
};

export default {
  "page-wrapper": "qvk",
  "back-button": "qvJ",
  "abort-button": "qvs",
  "container": "qvF",
  "subtitle": "qvg",
  "button": "qvD",
  "panel-list": "qvV",
  "panel-list-item": "qvL",
  "card-illustration": "qvT"
};

export default {
  "main-container": "qvq",
  "main": "qvZ",
  "main-wrapper": "qvR",
  "close-button": "qvQ",
  "active-block": "qve",
  "title": "qvB",
  "list-options": "qvE",
  "card": "qvd",
  "card-container": "qvI",
  "card-image": "qvt",
  "card-tiles": "qvM"
};

/* eslint-disable @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from '@repo/design-system-kit';

import { ROLES } from 'qonto/constants/membership';
import normalize from 'qonto/utils/normalize-string';

export default class AccountingHubController extends Controller {
  lottiePlayer = LottiePlayer;

  @tracked searchQuery = '';
  @tracked filteredItems = this.model?.organizationsToRoleMap;

  get organizationsToRoleMap() {
    return this.model?.organizationsToRoleMap;
  }

  @action
  filterItems(query) {
    this.searchQuery = query;

    this.filteredItems = this.organizationsToRoleMap.filter(({ organization }) =>
      normalize(organization.name).includes(normalize(query))
    );
  }

  get businessItems() {
    return this.organizationsToRoleMap.filter(({ role }) => role !== ROLES.REPORTING);
  }

  get clientItems() {
    return this.organizationsToRoleMap.filter(({ role }) => role === ROLES.REPORTING);
  }
}

export default {
  "wrapper": "qjL",
  "integrations": "qjT",
  "description": "qjz body-2",
  "api": "qja flex flex-column",
  "api-container": "qjH flex large",
  "api-input": "qjO large",
  "api-btn": "qjm",
  "key-input": "qjp"
};

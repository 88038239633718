export default {
  "container": "qGU",
  "form-container": "qGj",
  "title": "qGW",
  "title-border": "qGu",
  "tabs-container": "qrS",
  "preview-container": "qrc",
  "email-preview-container": "qrq",
  "close-button": "qrZ btn btn--icon-only btn--tertiary btn--large"
};

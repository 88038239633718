export default {
  "container": "qjc",
  "content": "qjq",
  "setup-box": "qjZ",
  "setup-wizard": "qjR",
  "setup-success": "qjQ",
  "setup-success-lottie": "qje",
  "setup-close-btn": "qjB",
  "tray-connection": "qjE",
  "close-button": "qjd"
};

export default {
  "container": "qKa",
  "content": "qKH",
  "logos": "qKO",
  "logo": "qKm",
  "animation": "qKp",
  "title": "qKx title-2 mb-32",
  "avatar": "qKw",
  "dropdown": "qKN",
  "disabled": "qKv",
  "organization": "qKo",
  "actions": "qKn"
};

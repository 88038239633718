export default {
  "title": "qWe title-3",
  "personal-profile-container": "qWB",
  "personal-profile-section": "qWE",
  "member-info-title": "qWd",
  "kyc-complete-profile": "qWI",
  "info-fields": "qWt",
  "field": "qWM",
  "address-fields": "qWP",
  "field-span": "qWl",
  "input": "qWX",
  "cancel": "qWC"
};

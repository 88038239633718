export default {
  "guests": "qAR",
  "guests--upsell-screen": "qAQ",
  "no-members": "qAe",
  "teams-header": "qAB",
  "empty": "qAE",
  "invite-text": "qAd",
  "body": "qAI",
  "members": "qAt",
  "members-list": "qAM",
  "members-title": "qAP caption-bold",
  "member": "qAl",
  "details": "qAX"
};

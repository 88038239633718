export default {
  "container": "qKl",
  "content": "qKX",
  "logos": "qKC",
  "logo": "qKk",
  "animation": "qKJ",
  "title": "qKs title-2 mb-32",
  "avatar": "qKF",
  "actions": "qKg mb-32",
  "footer": "qKD body-2"
};

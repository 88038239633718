export default {
  "container": "qrQ",
  "form-container": "qre",
  "preview-container": "qrB",
  "credit-note-preview-container": "qrE",
  "credit-note-preview": "qrd",
  "content": "qrI",
  "title": "qrt",
  "tabs-container": "qrM",
  "close-button": "qrP btn btn--icon-only btn--tertiary btn--large",
  "header": "qrl",
  "without-tabs": "qrX"
};

export default {
  "container": "qrD",
  "form-container": "qrV",
  "preview-container": "qrL",
  "pdf-preview-container": "qrT",
  "content": "qrz",
  "title": "qra",
  "tabs-container": "qrH",
  "close-button": "qrO btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qrm",
  "header": "qrp",
  "without-tabs": "qrx"
};

export default {
  "bank-accounts": "qyQ l-app-content__wrapper",
  "header-wrapper": "qye",
  "scrolled-top": "qyB",
  "header": "qyE l-app-content__page-header",
  "create-account-button": "qyd btn btn--primary",
  "main": "qyI",
  "accounts": "qyt",
  "subtitle-container": "qyM",
  "subtitle": "qyP title-3 mb-16",
  "amount": "qyl body-2",
  "list--cashbeeSavings": "qyX"
};

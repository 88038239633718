export default {
  "container": "qWy",
  "header-cell": "qWU caption-bold",
  "col-8": "qWj",
  "table": "qWW",
  "cell": "qWu body-2",
  "cell-content": "quS",
  "row": "quc",
  "empty": "quq",
  "quick-actions": "quZ",
  "card-infos": "quR",
  "card-digits": "quQ",
  "empty-state": "que"
};

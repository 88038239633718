export default {
  "supplier-invoices-page-header": "quy",
  "filters-container": "quU",
  "filters": "quj",
  "bulk-actions": "quW",
  "item-count": "quu",
  "higher-index": "ZSS",
  "title-wrapper": "ZSc",
  "title": "ZSq",
  "subtitle": "ZSZ",
  "table-container": "ZSR",
  "file-dropzone": "ZSQ",
  "dropzone-visible": "ZSe",
  "header-actions": "ZSB",
  "header-wrapper": "ZSE"
};

export default {
  "details": "qhf",
  "header": "qhK",
  "avatar": "qhG mr-16",
  "header-beneficiary": "qhr",
  "header-beneficiary-activity": "qhb",
  "header-beneficiary-details": "qhy",
  "header-beneficiary-details-recurring": "qhU",
  "infos": "qhj",
  "infos-title": "qhW",
  "infos-empty": "qhu",
  "infos-list": "qfS",
  "infos-list-item": "qfc",
  "infos-list-item-left": "qfq",
  "infos-list-item-right": "qfZ",
  "infos-list-item-attachment": "qfR",
  "infos-transactions": "qfQ",
  "actions": "qfe"
};

export default {
  "container": "qUk",
  "link-grey": "qUJ",
  "slack-section-title": "qUs title-4 mb-8",
  "edit-channel-text": "qUF body-2 mb-16",
  "rule-section-title": "qUg title-4 mb-16",
  "rule-cards-container": "qUD",
  "edit-slack-container": "qUV",
  "rule-card": "qUL",
  "add-rule-container": "qUT",
  "add-rule-link-button": "qUz",
  "add-rule-text": "qUa",
  "empty-state": "qUH",
  "empty-state-textblock": "qUO",
  "empty-state-title": "qUm title-2",
  "empty-state-description": "qUp body-2",
  "empty-state-illustration": "qUx",
  "rules": "qUw"
};

export default {
  "container": "qbQ",
  "form-container": "qbe",
  "preview-container": "qbB",
  "pdf-preview-container": "qbE",
  "content": "qbd",
  "title": "qbI",
  "tabs-container": "qbt",
  "close-button": "qbM btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qbP",
  "header": "qbl",
  "without-tabs": "qbX"
};

import Controller from '@ember/controller';
import { service } from '@ember/service';

import { getCategoryTitle } from 'qonto/utils/insurance-hub/categories';

export default class InsuranceHubCategoriesLoadingController extends Controller {
  @service router;
  @service intl;

  get title() {
    return getCategoryTitle(this.router.currentRoute.params.category_slug, this.intl);
  }
}

export default {
  "container": "qrN",
  "form-container": "qrv",
  "preview-container": "qro",
  "pdf-preview-container": "qrn",
  "content": "qri",
  "title": "qrA",
  "tabs-container": "qrY",
  "close-button": "qrh btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qrf",
  "header": "qrK",
  "without-tabs": "qrG"
};

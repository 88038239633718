export default {
  "page-wrapper": "qvW",
  "container": "qvu",
  "spinner-container": "qoS",
  "left-panel": "qoc",
  "form": "qoq",
  "left-content": "qoZ",
  "form-footer": "qoR",
  "delete-button": "qoQ",
  "header": "qoe",
  "preview-container": "qoB",
  "email-preview": "qoE",
  "close-button": "qod btn btn--icon-only btn--tertiary btn--large"
};

import Controller from '@ember/controller';
import { service } from '@ember/service';

import { LottiePlayer } from '@repo/design-system-kit';

import { getCategoryTitle } from 'qonto/utils/insurance-hub/categories';

export default class InsuranceHubCategoriesErrorController extends Controller {
  lottiePlayer = LottiePlayer;

  @service intl;
  @service router;

  get title() {
    return getCategoryTitle(this.categorySlug, this.intl);
  }

  get categorySlug() {
    return this.router.currentRoute.params.category_slug;
  }
}

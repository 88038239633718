export default {
  "mandates": "qfB",
  "mandates-empty": "qfE",
  "header": "qfd",
  "header-empty": "qfI",
  "header-content": "qft",
  "search": "qfM",
  "search-bar": "qfP",
  "search-spinner": "qfl",
  "body": "qfX",
  "isEmpty": "qfC",
  "left-section": "qfk",
  "mandates-list": "qfJ",
  "mandate-suspended": "qfs",
  "list-title": "qfF caption-bold",
  "list-empty": "qfg",
  "empty-illustration": "qfD",
  "empty-title": "qfV title-3",
  "empty-description": "qfL body-2",
  "body-details": "qfT",
  "l-app-content__page-header": "qfz",
  "pagination-footer": "qfa"
};

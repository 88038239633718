export default {
  "container": "qUc",
  "wrapper": "qUq",
  "grey-header-block": "qUZ",
  "connect-app-header": "qUR",
  "connect-app-header-link": "qUQ",
  "connect-app-header-content": "qUe",
  "connect-app-header-content-default": "qUB",
  "connect-app-header-content-left": "qUE",
  "button-block": "qUd",
  "connect-app-body": "qUI",
  "connect-app-body-left-content": "qUt",
  "connect-app-body-sidebar": "qUM"
};

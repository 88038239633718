import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class NewSupplierRoute extends Route {
  @service modals;
  @service intl;
  @service abilities;
  @service homePage;
  @service store;
  @service organizationManager;
  @service router;
  @service toastFlashMessages;
  @service sentry;
  @service segment;

  supplier;
  modal;
  referrerSupplierInvoiceId;

  beforeModel() {
    if (this.abilities.cannot('update supplier')) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  model(_, { from }) {
    if (from?.name === 'supplier-invoices.show') {
      this.referrerSupplierInvoiceId = from.params?.invoice_id;
    }

    this.supplier = this.store.createRecord('supplier', {
      countryCode: this.organizationManager.organization.legalCountry,
    });

    this.modal = this.modals.open(
      'suppliers/supplier-modal',
      {
        isFullScreenModal: true,
        title: this.intl.t('supplier-invoices.supplier-creation.title'),
        description: this.intl.t('supplier-invoices.supplier-creation.description'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('supplier-invoices.supplier-creation.cta.add-supplier'),
        confirmTask: this.createSupplierTask,
        supplier: this.supplier,
        referrerSupplierInvoiceId: this.referrerSupplierInvoiceId,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
          allowOutsideClick: false,
          escapeDeactivates: false,
        },
      }
    );

    return { supplier: this.supplier };
  }

  createSupplierTask = dropTask(async () => {
    try {
      await this.supplier.save();
      this.toastFlashMessages.toastSuccess(
        this.intl.t(
          'supplier-invoices.supplier-invoice-details.add-new-supplier.confirmation-toast'
        )
      );
      this.segment.track('add-supplier_submit', { result: 'success' });
      if (this.referrerSupplierInvoiceId) {
        await this.updateSupplierInvoice();
        this.router.transitionTo('supplier-invoices.show', this.referrerSupplierInvoiceId);
      } else {
        this.router.transitionTo('suppliers.index');
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      if (error.errors.some(errorObj => errorObj?.status === '409')) {
        this.toastFlashMessages.toastError(
          this.intl.t('supplier-invoices.supplier-invoice-details.form.duplicate-supplier-toast')
        );

        this.segment.track('add-supplier_duplicated_toast');
      } else {
        this.segment.track('add-supplier_submit', { result: 'error' });
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
      }
    }
  });

  async updateSupplierInvoice() {
    let invoice = await this.store.peekRecord('supplier-invoice', this.referrerSupplierInvoiceId);
    invoice.supplierName = this.supplier.name;
    invoice.supplierSnapshot = {
      id: this.supplier.id,
      name: this.supplier.name,
    };
    invoice.save();
  }

  deactivate() {
    this.modal.close();
  }
}

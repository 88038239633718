export default {
  "member-details": "qiz",
  "header": "qia",
  "header-img": "qiH",
  "invited-persona": "qiO",
  "revoked-persona": "qim",
  "invited-icon": "qip",
  "revoked-icon": "qix",
  "dropdown": "qiw",
  "header-body": "qiN",
  "name": "qiv title-3",
  "email": "qio caption-bold",
  "actions": "qin body-2",
  "actions-cards": "qii",
  "actions-transactions": "qiA",
  "body": "qiY",
  "expense-permissions-section": "qih",
  "body-title": "qif title-4",
  "body-attr": "qiK",
  "body-label": "qiG",
  "attr-buttons": "qir",
  "body-role": "qib small",
  "details-actions": "qiy",
  "actions-item": "qiU",
  "disclaimer": "qij",
  "permissions": "qiW",
  "permission": "qiu",
  "permission-label": "qAS",
  "permission-check": "qAc",
  "permission-missing": "qAq",
  "editable-section": "qAZ"
};

export default {
  "vat-number": "qjn body-2",
  "banner": "qji",
  "container": "qjA",
  "organization-profile": "qjY",
  "input": "qjh",
  "medium-input": "qjf medium",
  "small-input": "qjK small",
  "actions": "qjG",
  "company-info": "qjr",
  "company-info-section": "qjb",
  "company-address-section": "qjy",
  "company-country": "qjU",
  "company-billing-email": "qjj",
  "company-address": "qjW"
};

export default {
  "page-wrapper": "quA",
  "content-wrapper": "quY",
  "content": "quh",
  "period-field": "quf",
  "format-options": "quK",
  "format-option": "quG",
  "format-radio": "qur",
  "format-description": "qub body-2"
};

import Controller from '@ember/controller';
import { service } from '@ember/service';

import { reads } from 'macro-decorators';

import { OFFER_ROUTE } from 'qonto/constants/insurance-hub';
import { getCategoryTitle } from 'qonto/utils/insurance-hub/categories';

export default class InsuranceHubCategoriesController extends Controller {
  @service intl;
  @service organizationManager;
  @service router;
  @service segment;
  @service zendeskLocalization;

  @reads('organizationManager.organization') organization;

  get partnerships() {
    if (this.organization.isFrench) {
      return [
        { logo: '/illustrations/insurance-hub/axa.svg', name: 'Axa' },
        { logo: '/illustrations/insurance-hub/stello.svg', name: 'Stello' },
      ];
    } else if (this.organization.isGerman) {
      return [{ logo: '/illustrations/insurance-hub/signal.svg', name: 'Signal' }];
    }
  }

  get title() {
    return getCategoryTitle(this.router.currentRoute.params.category_slug, this.intl);
  }

  get displayCoachTile() {
    return this.organization.isFrench;
  }

  get faqLink() {
    let key = this.organization.isGerman ? 'insurance-hub-signal' : 'insurance-hub-stello';

    return this.zendeskLocalization.getLocalizedArticle(key);
  }

  get offerRoute() {
    return OFFER_ROUTE;
  }
}

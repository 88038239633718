export default {
  "body": "qfH",
  "members": "qfO",
  "members-results": "qfm",
  "members-list": "qfp",
  "members-title": "qfx caption-bold",
  "member": "qfw",
  "empty-illustration": "qfN",
  "empty-title": "qfv title-3",
  "empty-description": "qfo body-2",
  "invitable-members": "qfn",
  "details": "qfi",
  "pagination-footer": "qfA"
};

export default {
  "accounts": "qwB",
  "header-subtitle": "qwE title-4",
  "subtitle-container": "qwd",
  "subtitle": "qwI title-3 mb-16",
  "amount": "qwt body-2",
  "list--cashbeeSavings": "qwM",
  "connections-banner": "qwP",
  "list": "qwl",
  "icon": "qwX",
  "badge": "qwC",
  "discover-tile": "qwk"
};

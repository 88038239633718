export default {
  "page-wrapper": "qxo",
  "container": "qxn",
  "sidebar": "qxi",
  "search-input": "qxA",
  "organizations-list": "qxY",
  "content": "qxh",
  "no-result": "qxf",
  "lottie-illustration": "qxK",
  "accounting-header": "qxG",
  "logo": "qxr",
  "accounting-title": "qxb"
};

export default {
  "page-header": "ZcL",
  "header-breadcrumb": "ZcT",
  "tabs-container": "Zcz",
  "bottom-border": "Zca",
  "tabs-separator": "ZcH",
  "previous-request": "ZcO",
  "body-link": "Zcm",
  "border-top": "Zcp"
};

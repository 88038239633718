export default {
  "page-container": "qhR",
  "form": "qhQ",
  "close-button": "qhe btn btn--icon-only btn--tertiary btn--large",
  "preview": "qhB",
  "preview-padding": "qhE",
  "title": "qhd",
  "header": "qhI",
  "without-tabs": "qht",
  "form-section": "qhM",
  "form-footer": "qhP",
  "disclaimer-text": "qhl"
};
